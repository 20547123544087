import React from 'react';
import PropTypes from 'prop-types';
import CustomerPortal from '@cleverrealestate/clever-components-v2/dist/components/CustomerPortal';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const CustomerPortalTemplate = ({ pageContext }) => {
  const { rawSteps } = pageContext;

  return (
    <Layout
      colorTransitionStart={-1}
      shadowTransitionStart={50}
      colorTransitionLength={1}
      shadowTransitionLength={125}
      isWhite
    >
      <SEO
        pathname="/portal/"
        title="Customer Portal"
        meta={[{ name: 'robots', content: 'noindex' }]}
      />

      <CustomerPortal rawSteps={rawSteps} />
    </Layout>
  );
};

CustomerPortalTemplate.propTypes = {
  pageContext: PropTypes.any,
};

CustomerPortalTemplate.defaultProps = {
  pageContext: {},
};

export default CustomerPortalTemplate;
